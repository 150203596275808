<template>
  <custom-page>
    <group-card :title="$t('newadd.t.选择工况') " >
		<!-- 换热器量 -->
		<group-item label="custom.page2.换热量" v-if="isHot">
		  <group-item-input autosize v-model="exhaustHeatLoad" size="small" type="number"/>
		  <span class="unit">{{ userUnit.heatLoad }}  </span>
		</group-item>
		
		
		
      <!-- 工况类型 -->
      <group-item label="custom.page0.工况类型"  >
        <el-radio-group v-model="work" @change="onWorkSelect" >
          <el-radio :label="w" v-for="(w,i) in workList" :key="i">{{ $lang(w, 'name') }}</el-radio>
        </el-radio-group>
      </group-item>

      <!-- 冷媒 -->
      <group-item label="custom.page0.冷媒">
        <el-select v-model="form.refrigerant" size="small">
          <el-option :value="r.text" :label="r.text" v-for="(r,i) in source.refrigerant_type" :key="i"></el-option>
        </el-select>
      </group-item>

      <!-- 排气温度 -->
      <group-item label="custom.page0.排气温度">
        <group-item-input autosize v-model="form.exhaustTemperature" size="small" type="number"/>
        <span class="unit">{{ userUnit.temperature }}</span>
      </group-item>

      <!-- 排气压力 -->
      <group-item label="custom.page0.排气压力">
        <group-item-input autosize v-model="form.exhaustPressure" size="small" type="number"/>
        <span class="unit">{{ userUnit.pressure }}</span>
      </group-item>

      <!-- 过冷度 -->
      <group-item label="custom.page0.过冷度">
        <group-item-input autosize v-model="form.supercooling" size="small" type="number"/>
        <span class="unit">{{ userUnit.temperature }}</span>
      </group-item>

      <!-- 环境温度 -->
      <group-item label="custom.page0.环境温度">
        <group-item-input autosize v-model="form.ambientTemperature" size="small" type="number"/>
        <span class="unit">{{ userUnit.temperature }}</span>
      </group-item>

      <!-- 风速 -->
      <group-item label="custom.page0.风速">
        <group-item-input autosize v-model="form.windSpeed" size="small" type="number" :disabled="windType != 0"/>
        <span class="unit">{{ userUnit.velocity }}</span>
		
        <el-dropdown @command="handleCommand" placement="bottom">
          <span class="el-dropdown-link" style="cursor: pointer">
            {{ $t(source.windType[windType].title) }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="w.value" v-for="(w,i) in source.windType" :key="i">
              {{ $t(w.title) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </group-item>

      <!-- 选择风机曲线 -->
      <group-item label="custom.page0.选择风机" v-if="windType == 1">
        <el-cascader size="small" :options="source.fanList" :props="fanSelectProps" :show-all-levels="false"
						 :placeholder="$lang(form.fanCurve,'name')" :value="form.fanCurve" @change="handleFanCurveChange"
						 v-if="showSelectFan" class="fan-select" />
      </group-item>

      <!-- 风机数量 -->
      <group-item label="custom.page0.风机数量" v-if="windType!=0">
        <group-item-input autosize v-model="form.fanNum" size="small" type="number"/>
      </group-item>

      <!--输入风机曲线-->
      <group-item label="custom.page0.输入风机曲线" v-if="windType == 2">
        <div class="input-fan">
          <div class="th tr">
            <div class="td">
              <span>{{ $t("custom.page0.风量") }} </span>
              <span> [{{userUnit.volumeFlow }}]</span>
            </div>
            <div class="td">
              <span>{{ $t("custom.page0.风压") }} </span>
              <span>[{{ userUnit.airPressureDrop }}]</span>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v1" :placeholder="$t('newadd.t.请填写数据')"/>
			  
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p1"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v2"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p2"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td"> 
              <el-input type="number" v-model="form.fanCurve.v3"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p3"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v4"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p4"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v5"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p5"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v6"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p6"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v7"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p7"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
          <div class="tr">
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.v8"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
            <div class="td">
              <el-input type="number" v-model="form.fanCurve.p8"  :placeholder="$t('newadd.t.请填写数据')"/>
            </div>
          </div>
        </div>
      </group-item>

    </group-card>

    <div class="action-bar">
      <el-button type="primary" class="btn" @click="handleNextClick">{{ $t("common.actions.next") }}</el-button>
    </div>
  </custom-page>
</template>

<script>
import GroupCard from "@/views/Custom/components/GroupCard";
import CustomPage from "@/views/Custom/components/CustomPage";
import GroupItem from "@/views/Custom/components/GroupItem";
import GroupItemInput from "@/views/Custom/components/GroupItemInput";
import mixin from "@/views/Custom/components/mixins/mixins";
import {getDictListBatch} from "@/api/common";
import {Temp,Pressure,DTemp,Vel} from './cov-unit-user.js';

export default {
  name: "page0",
  components: {GroupItemInput, GroupItem, CustomPage, GroupCard},
  props:{
	isHot:{
		type:Boolean,
		default:false
	}  
  },
  data() {
	  
    return {
      work: null,
      workList: [],
	  exhaustHeatLoad:"", //换热量
      form: {
        // 冷媒
        refrigerant: "",
        // 排气温度
        exhaustTemperature: 0,
        // 排气压力
        exhaustPressure: 0,
        // 过冷度
        supercooling: 0,
        // 环境温度
        ambientTemperature: 0,
        // 风速
        windSpeed: 0,
        // 风速数量
        fanNum: 1,
        // 风机曲线
        fanCurve: {
          windVolumeUnit: "ft∧3/min",
          windPressureUnit: "Pa",
          v1: 0, v2: 0, v3: 0, v4: 0, v5: 0, v6: 0, v7: 0, v8: 0,
          p1: 0, p2: 0, p3: 0, p4: 0, p5: 0, p6: 0, p7: 0, p8: 0,
        },
		windType:'',
        // 风机曲线id
        fanCurveId: null,
        // 是否使用风机曲线
        useDiyFanCurve: true,
		
      },
	  
      windType: 0,
      source: {
        // 冷媒
        refrigerant_type: [],
        // 风机厂家
        factoryList: [],
        // 风机列表
        fanList: [],
        // 风机类型
        windType: [
          {title: "custom.page0.无风机", value: 0,},
          {title: "custom.page0.选择风机", value: 1,},
          {title: "custom.page0.自定义风机曲线", value: 2,},
        ],
      },
      showSelectFan: true,
      customFanCurve: {
        windVolumeUnit: "ft∧3/min",
        windPressureUnit: "Pa",
        v1: "", v2: "", v3: "", v4: "", v5: "", v6: "", v7: "", v8: "",
        p1: "", p2: "", p3: "", p4: "", p5: "", p6: "", p7: "", p8: "",
      },
      fanSelectProps: {
        expandTrigger: 'hover',
        label: this.$langKey("label")
      },
      userUnit: {},
    }
  },
  mixins: [mixin],
  computed: {},
  created(){
      
  },
  async mounted() {
  await  this.getUserUnit();
    this.getWorkList(true);
    this.getRefrigerantList();
    this.getSystemFanList();
    this.getMyFan();
    
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.$set(this.fanSelectProps, "label", this.$langKey("label"))
      // 手动隐藏一下选择器，刷新label的显示
      this.showSelectFan = false;
      this.$nextTick(() => {
        this.showSelectFan = true;
      })
    }
  },
  methods: {
    // 获取工况
    getWorkList(first) {
      this.$req.get("/client/goods/workingCondition/list").then(res => {
		  if(res.length){
			  this.workList = res;
			  if (first) {
			  
			    this.work = res[0];
				console.log(  this.work,"000")
			    this.onWorkSelect(this.work);
			  }
		  }
     
      })
    },
    // 获取冷媒
    getRefrigerantList() {
      getDictListBatch("refrigerant_type").then(res => {
        Object.assign(this.source, res)
      })
    },
    // 获取内置风机曲线
    getSystemFanList() {
      this.$req.get("/client/goods/fanCurve/systemList").then(res => {
        let parent = {};
        for (const fan of res) {
          let factory = fan.factoryZhCn;
          let parentFactory = parent[factory]
          if (!parentFactory) {
            let p = {
              labelZhCn: factory,
              labelEnUs: fan.factoryEnUs,
              value: factory,
              children: [],
            }
            parent[factory] = p
            parentFactory = p
          }
          parentFactory.children.push({
            labelZhCn: fan.nameZhCn,
            labelEnUs: fan.nameEnUs,
            value: fan,
          })
          
        }

        this.$set(this.source, 'fanList', Object.values(parent))
		
		
		
      })
    },
    // 获取自己的风机曲线
    getMyFan() {
      this.$req.get("/client/goods/fanCurve/list").then(res => {
		  if(res.length){
			  this.customFanCurve = res[0];
		  }
      })
    },
    async getUserUnit() {
      this.userUnit = await this.$store.dispatch("GetUserUnitOrCache");
	  
	  

    },

    onWorkSelect(model) {
      this.form.ambientTemperature =  Temp(model.ambientTemperature,this.userUnit.temperature); //环境温度
	  // this.form.ambientTemperatureUnit = model.ambientTemperatureUnit;
      this.form.exhaustPressure = Pressure(model.exhaustPressure,this.userUnit.pressure);  //排气压力
      // this.form.exhaustPressureUnit = model.exhaustPressureUnit;
      this.form.exhaustTemperature = Temp( model.exhaustTemperature,this.userUnit.temperature);  //排气温度
      // this.form.exhaustTemperatureUnit = model.exhaustTemperatureUnit;
      this.form.refrigerant = model.refrigerant;
      this.form.supercooling = DTemp(model.supercooling,this.userUnit.temperature) ;  //过冷度
      // this.form.supercoolingUnit = model.supercoolingUnit;
      this.form.useDiyFanCurve = model.useDiyFanCurve;
      this.form.windSpeed = Vel(model.windSpeed,this.userUnit.velocity);  //风速
      // this.form.windSpeedUnit = model.windSpeedUnit;
      this.form.fanNum = model.fanNum?model.fanNum:1;
      if (model.fanCurve) {
        Object.assign(this.form.fanCurve, model.fanCurve)
        // this.selectFactory = model.fanCurve.factoryZhCn
        // this.handleFactorySelect({title: this.selectFactory})
        this.form.fanCurveId = model.fanCurve.id;
      }
      this.windType = model.useDiyFanCurve ? 1 : 0;
    },
    handleFanCurveChange(val) {
			this.form.fanCurve=val[1];
    },
    handleCommand(command) {
      let type = Number(command);
      this.windType = type
      this.form.useDiyFanCurve = !!type;
      this.form.windType = type;
      if (command == 2) {
        this.$set(this.form, "fanCurve", this.customFanCurve)
      }
	  
	  
    },
    beforeNext() {
		
      this.checkFanCurveCustom();
      this.$store.commit("CLEAR_CUSTOM_INFO")
      this.$store.commit("SET_CUSTOM_INFO", {work: this.form})
	  

      return true;
    },
    checkFanCurveCustom() {
      let fanCurve = this.form.fanCurve;
      if (this.windType == 2) {
        if (fanCurve.id === undefined || fanCurve.id === null) {
          this.$req.post("/client/goods/fanCurve/add", fanCurve)
        } else {
          this.$req.post("/client/goods/fanCurve/update", fanCurve)
        }
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.page-wrapper {

}

.unit {
  display: inline-block;
  width: 100px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}

.input-fan {
  border: 1px solid #D0D1D2;
  border-radius: 4px;

  .tr {
    display: flex;
    border-top: 1px solid #D0D1D2;

    &.th {
      border: none;
      font-size: 14px;
    }

    .td {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      & + .td {
        border-left: 1px solid #D0D1D2;
      }

      ::v-deep .el-input .el-input__inner {
        border: none;
        text-align: center;
        color: #CC9977;;
        font-size: 14px;

      }
    }
  }
}

.fan-select {
  $placeholderColor: #717171;

  ::v-deep.el-input__inner {
    &::-webkit-input-placeholder {
      color: $placeholderColor !important;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $placeholderColor !important;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $placeholderColor !important;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $placeholderColor !important;
    }
  }
}

.action-bar {
  text-align: center;
  background: transparent;

  .btn {
    width: 330px;
  }
}
</style>